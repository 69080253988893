.carousel-container {
  width: 80%;
  max-width: 680px;
}
.news-carousel-container {
  width: 100%;
}
@media screen and (max-width: 920px) {
  .carousel-container {
    max-width: 420px;
    width: 80%;
  }
}

/* .carousel-item {
  width: 50%;
} */
